import { TemplateType } from "../enums/template-type";

export function prettifyTemplateType(type: TemplateType): string {
  switch (type) {
    case TemplateType.Custom:
      return 'Custom';
    case TemplateType.Campaign:
      return 'Broadcast';
    case TemplateType.CampaignWithPayment:
      return 'Broadcast with Payment';
    case TemplateType.NewTicket:
      return 'New conversation';
    case TemplateType.Reinitialise:
      return 'Reinitialise';
    case TemplateType.FormRequest:
      return 'Form request';
    case TemplateType.PaymentRequest:
      return 'Payment request';
    case TemplateType.PaymentPaid:
      return 'Payment paid';
    case TemplateType.PaymentRefunded:
      return 'Payment refunded';
    case TemplateType.PreAuthorisationSuccess:
      return 'Pre-auth success';
    case TemplateType.Notification:
      return 'System notification';
    case TemplateType.ProductRequestUpdate:
      return 'Product Request Update';
    case TemplateType.Welcome:
      return 'Welcome';
    case TemplateType.Standard:
      return 'Standard';
    default:
      return 'N/A';
  }
}
