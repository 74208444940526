import {TemplateType} from '../enums/template-type';

export function templateTypeCanHaveMedia(type: TemplateType): boolean {
  const validTypes = [
    TemplateType.Campaign,
    TemplateType.CampaignWithPayment,
    TemplateType.Standard
  ];

  return !!validTypes.includes(type);
}
