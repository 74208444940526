import {Message} from '../models/Message';
import {MessageDto} from '../interfaces/dto/message.dto';
import {UserAdapter} from './user.adapter';
import {Injectable} from '@angular/core';
import {MessageAttachmentAdapter} from './message-attachment.adapter';

@Injectable({
  providedIn: 'root'
})
export class MessageAdapter {
  constructor(private userAdapter: UserAdapter, private messageAttachmentAdapter: MessageAttachmentAdapter) {
  }

  run(dto: MessageDto): Message {
    return {
      id: dto.id.toString(),
      author: dto.author ? this.userAdapter.run(dto.author) : undefined,
      attachments: dto.attachments ? dto.attachments.map((attachment) => this.messageAttachmentAdapter.run(attachment)) : [],
      content: dto.content,
      status: dto.status,
      outbound: !!dto.outbound,
      createdAt: new Date(dto.createdAt),
      type: dto.type,
      templateId: dto.templateId,
      buttons: dto.buttons,
      error: dto.error,
      conversationId: dto.conversationId,
    };
  }
}
