import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiffForHumansPipe } from '../pipes/diff-for-humans.pipe';
import { DateOrDiffPipe } from '../pipes/date-or-diff.pipe';
import { ShowMessageDatePipe } from '../pipes/show-message-date.pipe';
import { Nl2brPipe } from '../pipes/nl2br.pipe';
import { BuildLinksPipe } from '../pipes/build-links.pipe';
import { TrimPipe } from '../pipes/trim.pipe';
import { PenceToPounds } from '../pipes/pence-to-pounds.pipe';
import { ClientBalancePipe } from '../pipes/client-balance.pipe';
import { ParseIntPipe } from '../pipes/parse-int.pipe';
import { MentionsPipe } from '../pipes/style-mentions.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { MinutesAndSecondsPipe } from '../pipes/miuntes-and-seconds.pipe';
import { DateInPastPipe } from '../pipes/date-in-past.pipe';
import { ShowConsentMessagePipe } from '../pipes/show-consent-message.pipe';
import { GetLastConsentDateTimePipe } from '../pipes/get-last-consent-date-time.pipe';
import { PaymentAuthoriseExpiredPipe } from '../pipes/payment-authorise-expired.pipe';
import {PrettifyTemplateTypePipe} from '../pipes/prettify-template-type.pipe';
import {UnderscoresToSpacesPipe} from '../pipes/underscores-to-spaces.pipe';
import { GetPaymentStatusPipe } from '../pipes/get-payment-status.pipe';
import { CanPaymentSyncBeRetriedPipe } from '../pipes/can-payment-sync-be-retried.pipe';
import { GetPaymentExpiryPipe } from '../pipes/get-payment-expiry.pipe';
import { CanPaymentBeResentPipe } from '../pipes/can-payment-be-resent.pipe';
import { CanPaymentBeRefundedPipe } from '../pipes/can-payment-be-refunded.pipe';
import { CanPaymentBeCompletedPipe } from '../pipes/can-payment-be-completed.pipe';
import { CanPaymentBeCapturedPipe } from '../pipes/can-payment-be-captured.pipe';
import { CanPaymentBeCancelledPipe } from '../pipes/can-payment-be-cancelled.pipe';
import { GetPaymentStatusChangeDatePipe } from '../pipes/get-payment-status-change-date.pipe';
import { PrettifyTemplateSubmissionTypePipe } from '../pipes/prettify-template-submission-status.pipe';
import {PrettifyTemplateContentPipe} from '../pipes/prettify-template-placeholder.pipe';
import {CanTemplateBeEditedPipe} from '../pipes/can-template-be-edited.pipe';
import { ColumnMappingNamePipe } from '../pipes/column-mapping-value.pipe';
import { PrettifyProductRequestStatusPipe } from '../pipes/prettify-product-request-status.pipe';
import { GetProductRequestStatusColorPipe } from '../pipes/get-product-request-status-color.pipe';
import { ApprovalRejectedProductRequestItemCountPipe } from '../pipes/approval-rejected-product-request-item-count.pipe';
import { ApprovedProductRequestItemCountPipe } from '../pipes/approved-product-request-item-count.pipe';
import { AwaitingApprovalProductRequestItemCountPipe } from '../pipes/awaiting-approval-product-request-item-count.pipe';
import { ApprovalNotRequiredProductRequestItemCountPipe } from '../pipes/approval-not-required-product-request-item-count.pipe';
import { GetFilterLabelPipe } from '../pipes/get-filter-label.pipe';
import { ShouldShowDispensedCountPipe } from '../pipes/should-show-dispensed-count.pipe';
import {ItemCanBeDispensedPipe} from '../pipes/item-can-be-dispensed.pipe';
import {SecondsToStrPipe} from '../pipes/seconds-to-string.pipe';
import {CanViewDashboardPipe} from '../pipes/can-view-dashboard.pipe';
import {CanViewDaylistPipe} from '../pipes/can-view-daylist.pipe';
import {CanViewAllConversationsPipe} from '../pipes/can-view-all-conversations.pipe';
import { CanViewBroadcastsPipe } from '../pipes/can-view-broadcasts.pipe';
import { CanViewPaymentsPipe } from '../pipes/can-view-payments.pipe';
import {CanViewMediaPipe} from '../pipes/can-view-media.pipe';
import { CanViewTemplatesPipe } from '../pipes/can-view-templates.pipe';
import { CanViewClinicSettingsPipe } from '../pipes/can-view-clinic-settings.pipe';
import { PrettifyTemplateCategoryPipe } from '../pipes/prettify-template-category.pipe';
import { TitleCasePipe } from '../pipes/title-case.pipe';
import {CanTemplateBeArchivedPipe} from '../pipes/can-template-be-archived.pipe';
import {GetConversationLatestMessagePipe} from '../pipes/get-conversation-latest-message.pipe';
import {PrettifyMessageTypePipe} from '../pipes/prettify-message-type.pipe';
import {TemplateTypeCanHaveMediaPipe} from '../pipes/template-type-can-have-media.pipe';
import {PrettifyFormInputType} from '../pipes/prettify-form-input-type.pipe';
import {FieldTypeHasOptionsPipe} from '../pipes/field-type-has-options.pipe';
import {FieldCanBePrefilledPipe} from '../pipes/field-can-be-prefilled.pipe';
import {PrettifyFormFieldPrefillOption} from '../pipes/prettify-form-field-prefill-option.pipe';
import {FieldTypeHasPlaceholderPipe} from '../pipes/field-type-has-placeholder.pipe';
import {FieldTypeCanBeSensitivePipe} from '../pipes/field-type-can-be-sensitive.pipe';
import {GetFieldPrefillValuePipe} from '../pipes/get-field-prefill-value.pipe';
import { GetSubmittedFieldValuePipe } from '../pipes/get-submitted-field-value.pipe';
import {GetSubmittedFieldLabelPipe} from '../pipes/get-submitted-field-label.pipe';
import {ConversationIsSelectedPipe} from '../pipes/conversation-is-selected.pipe';
import {CanViewFormsPipe} from "../pipes/can-view-forms.pipe";
import {FieldTypeHasDefaultOptionPipe} from "../pipes/field-type-has-default-option.pipe";

@NgModule({
  declarations: [
    TrimPipe,
    ClientBalancePipe,
    ParseIntPipe,
    BuildLinksPipe,
    MinutesAndSecondsPipe,
    DiffForHumansPipe,
    DateOrDiffPipe,
    DateInPastPipe,
    SecondsToStrPipe,
    GetConversationLatestMessagePipe,
    ShowMessageDatePipe,
    ShowConsentMessagePipe,
    ShouldShowDispensedCountPipe,
    ConversationIsSelectedPipe,
    GetLastConsentDateTimePipe,
    Nl2brPipe,
    PenceToPounds,
    MentionsPipe,
    TruncatePipe,
    UnderscoresToSpacesPipe,
    PaymentAuthoriseExpiredPipe,
    PrettifyMessageTypePipe,
    PrettifyTemplateTypePipe,
    PrettifyTemplateCategoryPipe,
    PrettifyTemplateContentPipe,
    TitleCasePipe,
    CanTemplateBeEditedPipe,
    GetPaymentStatusPipe,
    CanPaymentSyncBeRetriedPipe,
    GetPaymentExpiryPipe,
    CanPaymentBeResentPipe,
    CanPaymentBeRefundedPipe,
    CanPaymentBeCompletedPipe,
    CanPaymentBeCapturedPipe,
    CanPaymentBeCancelledPipe,
    GetPaymentStatusChangeDatePipe,
    CanTemplateBeArchivedPipe,
    PrettifyTemplateSubmissionTypePipe,
    ColumnMappingNamePipe,
    PrettifyProductRequestStatusPipe,
    GetProductRequestStatusColorPipe,
    ApprovalRejectedProductRequestItemCountPipe,
    ApprovedProductRequestItemCountPipe,
    AwaitingApprovalProductRequestItemCountPipe,
    ApprovalNotRequiredProductRequestItemCountPipe,
    GetFilterLabelPipe,
    ItemCanBeDispensedPipe,
    CanViewDashboardPipe,
    CanViewDaylistPipe,
    CanViewAllConversationsPipe,
    CanViewBroadcastsPipe,
    CanViewPaymentsPipe,
    CanViewMediaPipe,
    CanViewTemplatesPipe,
    CanViewFormsPipe,
    CanViewClinicSettingsPipe,
    TemplateTypeCanHaveMediaPipe,
    PrettifyFormInputType,
    FieldTypeHasOptionsPipe,
    FieldTypeHasPlaceholderPipe,
    FieldTypeCanBeSensitivePipe,
    FieldCanBePrefilledPipe,
    PrettifyFormFieldPrefillOption,
    GetFieldPrefillValuePipe,
    GetSubmittedFieldValuePipe,
    GetSubmittedFieldLabelPipe,
    FieldTypeHasDefaultOptionPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClientBalancePipe,
    ParseIntPipe,
    BuildLinksPipe,
    MinutesAndSecondsPipe,
    DiffForHumansPipe,
    DateOrDiffPipe,
    DateInPastPipe,
    SecondsToStrPipe,
    GetConversationLatestMessagePipe,
    ShowMessageDatePipe,
    ShowConsentMessagePipe,
    ShouldShowDispensedCountPipe,
    ConversationIsSelectedPipe,
    GetLastConsentDateTimePipe,
    Nl2brPipe,
    TrimPipe,
    PenceToPounds,
    MentionsPipe,
    TruncatePipe,
    UnderscoresToSpacesPipe,
    PaymentAuthoriseExpiredPipe,
    PrettifyMessageTypePipe,
    PrettifyTemplateTypePipe,
    PrettifyTemplateCategoryPipe,
    PrettifyTemplateContentPipe,
    TitleCasePipe,
    CanTemplateBeEditedPipe,
    GetPaymentStatusPipe,
    CanPaymentSyncBeRetriedPipe,
    GetPaymentExpiryPipe,
    CanPaymentBeResentPipe,
    CanPaymentBeRefundedPipe,
    CanPaymentBeCompletedPipe,
    CanPaymentBeCapturedPipe,
    CanPaymentBeCancelledPipe,
    GetPaymentStatusChangeDatePipe,
    CanTemplateBeArchivedPipe,
    PrettifyTemplateSubmissionTypePipe,
    ColumnMappingNamePipe,
    PrettifyProductRequestStatusPipe,
    GetProductRequestStatusColorPipe,
    ApprovalRejectedProductRequestItemCountPipe,
    ApprovedProductRequestItemCountPipe,
    AwaitingApprovalProductRequestItemCountPipe,
    ApprovalNotRequiredProductRequestItemCountPipe,
    GetFilterLabelPipe,
    ItemCanBeDispensedPipe,
    CanViewDashboardPipe,
    CanViewDaylistPipe,
    CanViewAllConversationsPipe,
    CanViewBroadcastsPipe,
    CanViewPaymentsPipe,
    CanViewMediaPipe,
    CanViewTemplatesPipe,
    CanViewFormsPipe,
    CanViewClinicSettingsPipe,
    TemplateTypeCanHaveMediaPipe,
    PrettifyFormInputType,
    FieldTypeHasOptionsPipe,
    FieldTypeHasPlaceholderPipe,
    FieldTypeCanBeSensitivePipe,
    FieldCanBePrefilledPipe,
    PrettifyFormFieldPrefillOption,
    GetFieldPrefillValuePipe,
    GetSubmittedFieldValuePipe,
    GetSubmittedFieldLabelPipe,
    FieldTypeHasDefaultOptionPipe
  ]
})
export class UtilsModule { }
