<ng-container *ngIf="paymentRequestLoading">
  <p-progressSpinner [style]="{width: '32px', height: '32px', margin: '42px auto', display: 'block'}" styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!paymentRequestLoading">
  <ng-container *ngIf="paymentRequestFailed">
    <p class="error">Payment request could not be created, please ensure your practice has been linked to your Stripe account and that the phone number selected is valid.</p>
  </ng-container>
  <ng-container *ngIf="!paymentRequestFailed">
    <div class="payment-link-form-wrap">
      <ng-container *ngIf="device != 'mobile' || previewOpen == false">
        <div class="payment-link-form">
          <ng-container *ngIf="errors.length > 0">
            <div class="errors">
              <ng-container *ngFor="let error of errors">
                <div class="form-error">{{ error }}</div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="sites && sites.length > 1">
            <div class="input-row">
              <div class="label">
                Transaction Site
              </div>
              <div class="input-holder full-width">
                <select class="primary" [(ngModel)]="paymentSite">
                  <option value="0">Please select</option>
                  <ng-container *ngFor="let site of sites">
                    <option value="{{ site.id }}">{{ site.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="showOptionalMessage">
            <div class="input-row">
              <div class="label">
                Optional message to client
              </div>
              <div class="input-holder full-width">
                <textarea #paymentOptionalMessageField pInputTextarea [class.invalid]="optionalMessageTooLong"
                  [formControl]="paymentMessage" [rows]="2" [autoResize]="true" (keyup)="buildPaymentMessage()"
                  (onResize)="optionalTextareaResize($event)">
                                              </textarea>
              </div>
              <div class="under-optional-message">
                <div>
                  <ng-container *ngIf="templates.length > 0">
                    <div class="use-template-link" (click)="selectTemplate()">Use template</div>
                  </ng-container>
                </div>
                <div class="character-count" [class.invalid]="optionalMessageTooLong">{{ remainingOptionalMessageLength }}</div>
              </div>
            </div>
          </ng-container>
          <div class="input-row">
            <div class="label">
              Description
            </div>
            <div class="input-holder full-width">
              <textarea #paymentDescriptionField pInputTextarea [class.invalid]="paymentDescriptionTooLong" [rows]="1"
                [autoResize]="true" [formControl]="paymentDescription" placeholder="Shown on clinic record and payment screens"
                (keyup)="buildPaymentMessage()" (onResize)="descriptionTextareaResize($event)"></textarea>
            </div>
            <div class="under-payment-description">
              <div class="character-count" [class.invalid]="paymentDescriptionTooLong">{{ remainingPaymentDescriptionLength }}</div>
            </div>
          </div>
          <ng-container *ngIf="(outstanding | parseInt) > 0 && balanceEnabled">
            <div class="input-row single-line">
              <div>
                <label class="label"><span class="label-red">Account is  {{ (outstanding | clientBalance : practiceCurrency?.currencyCode : practiceCurrency?.currencySymbol) }}.</span>  <br /> Request full amount?</label>
              </div>
              <div class="checkbox-holder">
                <p-checkbox [(ngModel)]="requestFullAmount" (onChange)="buildPaymentMessage()" name="selected" [binary]="true"></p-checkbox>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!requestFullAmount">
            <div class="input-row single-line">
              <span class="label">Amount requested:</span>
              <div class="input-holder amount">
                <span class="pound">{{ practiceCurrency?.currencySymbol || '£' }}</span>
                <input pInputText class="amount-input" (keydown)="preventNonNumericChars($event)" (keyup)="$event.key == 'Enter' ? submitPaymentRequest(): buildPaymentMessage()" [(ngModel)]="paymentValue" placeholder="0.00" [style]="{width: '100%'}" [class.error]="paymentAmountError">
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="holdAndCaptureEnabled && showPreAuth">
            <div class="input-row single-line top-aligned">
              <div>
                <label class="label">Pre-authorise client's card</label>
                <div class="more-info">Funds will be held for 7 days until charged or released.</div>
              </div>
              <p-checkbox [(ngModel)]="authOnly" (onChange)="buildPaymentMessage()" name="selected" [binary]="true"></p-checkbox>
            </div>
          </ng-container>
          <ng-container *ngIf="customExpiryAllowed && showAdvancedOptions">
            <div class="advanced-opener" (click)="advancedOpen = !advancedOpen">
              <ng-container *ngIf="!advancedOpen">
                Advanced settings
              </ng-container>
              <ng-container *ngIf="advancedOpen">
                Hide advanced settings
              </ng-container>
            </div>
            <ng-container *ngIf="advancedOpen">
              <div class="input-row">
                <div class="label">Link expires after:</div>
                <div class="input-holder full-width">
                  <select class="primary" (change)="buildPaymentMessage()" [(ngModel)]="paymentExpiry">
                    <ng-container *ngFor="let expiryOption of expiryOptions">
                      <option value="{{ expiryOption.amount }}">{{ expiryOption.label }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="btn-row">
            <ng-container *ngIf="device == 'mobile'">
              <div class="preview-link" (click)="togglePreview()">Preview</div>
            </ng-container>
            <button (click)="submitPaymentRequest()" pButton label="Send Payment Request" [disabled]="optionalMessageTooLong || paymentDescriptionTooLong"></button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="device != 'mobile' || previewOpen == true">
        <div class="payment-link-preview">
          <div class="preview-title">
            Client message preview

            <ng-container *ngIf="device == 'mobile'">
              <div class="preview-link" (click)="togglePreview()">Close preview</div>
            </ng-container>
          </div>
          <div class="message-preview">
            <message
              [message]="messagePreview"
              [hideTimeStamp]="true"
              [fullWidth]="true"
            ></message>
          </div>
          <div class="preview-title">
            Payment screen preview
          </div>
          <div class="payment-screen-preview">
            <div class="lock-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="var(--vhd-color)" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
            </div>
            <div class="payment-header">
              <ng-container *ngIf="practice && practice.banner">
                <img class="logo" src="{{ practice.banner }}" alt="">
              </ng-container>
            </div>
            <div class="payment-info">
              <div class="row message">
                <ng-container *ngIf="paymentMessage.value.length > 0">
                  <p class="payment-message">"{{ paymentMessage.value }}"</p>
                </ng-container>
                <p class="practice-name">{{ practice?.name ?? '' }}</p>
              </div>
              <div class="row details">
                <div class="payment-description">{{ paymentDescription.value }}</div>
                <div class="payment-total">{{ practiceCurrency ? practiceCurrency.currencySymbol : '£' }}{{ getPaymentString() }}</div>
              </div>
              <ng-container *ngIf="authOnly">
                <div class="row">By proceeding, you are pre-authorising a payment to be taken within the next 7 days</div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
