export enum TemplateType {
  NewTicket = 'NewTicket',
  Reinitialise = 'Reinitialise',
  FormRequest = 'FormRequest',
  PaymentRequest = 'PaymentRequest',
  PaymentPaid = 'PaymentPaid',
  PaymentRefunded = 'PaymentRefunded',
  PreAuthorisationSuccess = 'PreAuthorisationSuccess',
  Custom = 'Custom',
  Campaign = 'Campaign',
  CampaignWithPayment = 'CampaignWithPayment',
  Notification = 'Notification',
  Welcome = 'Welcome',
  Standard = 'Standard',
  ProductRequestUpdate = 'ProductRequestUpdate',
}
