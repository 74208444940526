<ng-container *ngIf="conversation">
  <div class="conversation-list-item-wrap" [class.with-border]="showBorder">
    <ng-container *ngIf="conversation.syncFailures === 1">
      <div  class="pms-sync-warning" tooltipPosition="top" tooltipStyleClass="tooltip-error" pTooltip="One or more payments have not been synced to the PMS">
        <i class="pi pi-exclamation-triangle"></i>
      </div>
    </ng-container>
    <div class="primary-layout">
      <div (click)="conversationClicked($event)" class="conversation-list-item">
        <ng-container *ngTemplateOutlet="statusBar"></ng-container>

        <ng-container *ngIf="selectable">
          <ng-container *ngTemplateOutlet="selector"></ng-container>
        </ng-container>

        <div class="staleness">
          <ng-container *ngTemplateOutlet="newFlag"></ng-container>
          <ng-container *ngTemplateOutlet="createdAt"></ng-container>
        </div>

        <div class="client" [class.bold]="!conversation.resolvedAt && (conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0 || conversation.readStatus === readStatus.UNREAD)">
          <ng-container *ngTemplateOutlet="clientName"></ng-container>
          <ng-container *ngTemplateOutlet="patientName"></ng-container>
        </div>

        <div class="excerpts">
          <ng-container *ngTemplateOutlet="excerpt, context: {truncateLength:100}"></ng-container>
        </div>

        <div class="users">
          <ng-container *ngTemplateOutlet="owner"></ng-container>
          <ng-container *ngTemplateOutlet="assignee"></ng-container>
        </div>

        <div class="status">
          <ng-container *ngTemplateOutlet="status"></ng-container>
        </div>
      </div>
    </div>
    <div class="compact-layout">
      <div (click)="navigateToConversation()" class="conversation-list-item">
        <ng-container *ngTemplateOutlet="newFlagMobile"></ng-container>
        <div class="row">
          <div class="left">
            <ng-container *ngTemplateOutlet="statusBar"></ng-container>
            <ng-container *ngIf="selectable">
              <ng-container *ngTemplateOutlet="selector"></ng-container>
            </ng-container>
            <div class="text-holder">
              <div class="client-mobile" [class.bold]="!conversation.resolvedAt && (conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0 || conversation.readStatus === readStatus.UNREAD)">
                <ng-container *ngTemplateOutlet="clientNamePatientNameMobile"></ng-container>
              </div>
              <div class="excerpts">
                <ng-container *ngTemplateOutlet="excerpt; context: {truncateLength:80}"></ng-container>
              </div>
            </div>
          </div>
          <div class="right">
            <ng-container *ngTemplateOutlet="createdAt"></ng-container>
            <div class="status">
              <ng-container *ngTemplateOutlet="ownerCompact"></ng-container>
              <ng-container *ngTemplateOutlet="status"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #selector>
  <div class="selector" (click)="$event.stopPropagation()">
    <p-checkbox name="selected" (onChange)="selectedChanged()" [(ngModel)]="selected" [binary]="true"></p-checkbox>
  </div>
</ng-template>

<ng-template #statusBar>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="!conversation.resolvedAt && (conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0 || conversation.readStatus === readStatus.UNREAD)">
      <div class="status-tab" [ngStyle]="{'background-color': 'var(--alert-color)'}"></div>
    </ng-container>

    <ng-container *ngIf="conversation.resolvedAt || conversation.readStatus === readStatus.READ || (conversation.readStatus === readStatus.UNSET && conversation.newMessage === 0)">
        <div class="status-tab" [ngStyle]="{'background-color': 'var(--surface-0)'}"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #createdAt>
  <ng-container *ngIf="conversation">
    <div class="last-response">
      <ng-container *ngIf="useLastResponseDate === false">
        <div>{{ conversation.createdAt | diffForHumans:refresh }}</div>
      </ng-container>
      <ng-container *ngIf="useLastResponseDate === true">
        <div>{{ conversation.lastResponseAt | diffForHumans:refresh }}</div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #newFlagMobile>
  <ng-container *ngIf="conversation && !conversation.resolvedAt">
    <ng-container *ngIf="conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0">
      <div class="new-counter">
        {{ conversation.newMessage }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #newFlag>
  <ng-container *ngIf="conversation && !conversation.resolvedAt">
    <ng-container *ngIf="conversation.readStatus === readStatus.UNSET && conversation.newMessage > 0">
      <div class="new">
        <capsule [label]="'Unread ('+conversation.newMessage+')'" backgroundColor="var(--alert-color)" [small]="true"></capsule>
      </div>
    </ng-container>
    <ng-container *ngIf="conversation.readStatus === readStatus.UNREAD">
      <div class="new">
        <capsule [label]="'Unread'" backgroundColor="var(--alert-color)" [small]="true"></capsule>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #clientName>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="conversation.client">
      <div id="client" class="client-name">{{ conversation.client.firstName }} {{ conversation.client.lastName }}</div>
    </ng-container>
    <ng-container *ngIf="!conversation.client">
      <div class="client-name">Unmatched</div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #clientNamePatientNameMobile>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="conversation.client">
      <div class="client-name">{{ conversation.client.firstName }} {{ conversation.client.lastName }}</div>
    </ng-container>
    <ng-container *ngIf="!conversation.client">
      <div class="client-name">Unmatched</div>
    </ng-container>
    <ng-container *ngIf="conversation.patient">
      <div class="patient-name"> - {{ conversation.patient.name }}</div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #patientName>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="conversation.patient">
      <div class="client-pet">{{ conversation.patient.name }} - <ng-container *ngIf="conversation.patient.breed">{{ conversation.patient.breed }} </ng-container>({{ conversation.patient.species }})</div>
    </ng-container>
    <ng-container *ngIf="!conversation.patient">
      <div class="client-pet no-patient">No patient</div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #excerpt let-truncateLength='truncateLength'>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="conversation.summary">
      <div class="summary">
          {{ conversation.summary|truncate:truncateLength }}
      </div>
    </ng-container>
    <div class="recent-message">
      <ng-container *ngIf="conversation.latestMessage">
        <ng-container *ngIf="conversation.latestMessage.content.length > 0">
          {{ conversation.latestMessage.content|truncate:truncateLength }}&nbsp;
        </ng-container>
        <ng-container *ngIf="conversation.latestMessage.attachments && conversation.latestMessage.attachments.length > 0">
          <ng-container *ngFor="let attachment of conversation.latestMessage.attachments">
            <ng-container *ngIf="attachment.mime.includes('image')">
              (Image attached)
            </ng-container>
            <ng-container *ngIf="attachment.mime.includes('video')">
              (Video attached)
            </ng-container>
            <ng-container *ngIf="attachment.mime.includes('audio')">
              (Audio attached)
            </ng-container>
            <ng-container *ngIf="attachment.mime.includes('pdf')">
              (PDF attached)
            </ng-container>
            <ng-container *ngIf="!attachment.mime.includes('image') && !attachment.mime.includes('video') && !attachment.mime.includes('pdf') && !attachment.mime.includes('audio')">
              (File attached)
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!conversation.latestMessage">
        (No messages)
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #owner>
  <ng-container *ngIf="conversation">
    <div class="user">
      <div class="label">Owner</div>
      <user-avatar [user]="conversation.owner"></user-avatar>
    </div>
  </ng-container>
</ng-template>

<ng-template #ownerCompact>
  <ng-container *ngIf="conversation">
    <div class="user">
      <user-avatar [user]="conversation.owner" [size]="'small'"></user-avatar>
    </div>
  </ng-container>
</ng-template>

<ng-template #assignee>
  <ng-container *ngIf="conversation">
    <div class="user">
      <div class="label">Assignee</div>
      <user-avatar [user]="conversation.assignee"></user-avatar>
    </div>
  </ng-container>
</ng-template>

<ng-template #assigneeCompact>
  <ng-container *ngIf="conversation">
    <div class="user">
      <div class="label">Assignee</div>
      <user-avatar [user]="conversation.assignee" [size]="'small'"></user-avatar>
    </div>
  </ng-container>
</ng-template>

<ng-template #status>
  <ng-container *ngIf="conversation">
    <ng-container *ngIf="conversation.resolvedAt">
      <capsule label="Resolved" backgroundColor="var(--surface-500)" [small]="true"></capsule>
    </ng-container>
    <ng-container *ngIf="!conversation.resolvedAt">
      <ng-container *ngIf="conversation.status">
        <capsule [label]="conversation.status.name" [backgroundColor]="conversation.status.color" [small]="true"></capsule>
      </ng-container>
      <ng-container *ngIf="!conversation.status">
        <capsule label="Not set" backgroundColor="var(--surface-400)" [small]="true"></capsule>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #actions>
  <div class="actions-wrap" (clickOutside)="actionsOpen = false" (click)="$event.stopPropagation(); toggleActions()">
    <div class="actions-opener"><img src="assets/images/icons/dots.svg" alt=""></div>
    <ng-container *ngIf="actionsOpen">
      <div class="actions-dropdown">
        <div class="action" (click)="$event.stopPropagation();markRead()">
          Mark as read
        </div>
        <div class="action" (click)="$event.stopPropagation();markUnread()">
          Mark as unread
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
