import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as PaymentsActions from './actions';
import {PaymentsService} from '../payments.service';
import {getCurrentPractice} from '../../practices/state/selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import {Payment} from '../../models/Payment';
import {of} from 'rxjs';
import * as ConversationActions from '../../conversation/state/actions';
import { Noop } from '../../state/actions';
import { MessageService } from 'primeng/api';
import {CashUp, RefundPayment, RetrySavePaymentToPmsFailed, UpdatePaymentFilters} from './actions';
import {SearchType} from "../../enums/search-type";
import {buildUrlParamsFromPaymentFilters} from "../../helpers/build-url-params-from-payment-filters";
import * as SearchActions from "../../search/state/actions";
import {Router} from "@angular/router";
import {practiceHasFeature} from "../../helpers/practice-has-feature";
import {PracticeFeature} from "../../enums/practice-feature";
import { exportPaymentAsCsv } from '../../helpers/export-payments-as-csv';
import { PaymentStatus } from '../../enums/payment-status';

@Injectable()
export class PaymentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private paymentsService: PaymentsService,
    private messageService: MessageService,
  ) {
  }

  submitPaymentRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.createPayment(
        action.request.description,
        action.request.message,
        action.request.amount,
        action.request.expiresAfter,
        action.conversationId,
        action.clientId,
        action.patientId,
        practice?.id,
        action.siteId,
        true,
        action.request.authOnly
      )
        .pipe(
          map((result: Payment) => {
            return PaymentsActions.SubmitPaymentRequestSuccess({payment: result});
          })
        );
    }),
    catchError(() => {
      return of(PaymentsActions.SubmitPaymentRequestFailed());
    })
  ));

  submitPaymentRequestSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentRequestSuccess),
    mergeMap((action) => {
      if (action.payment.conversationId) {
        this.store.dispatch(ConversationActions.SetConversationAsRead({conversationId: action.payment.conversationId}));
      }

      return of();
    }),
  ), { dispatch: false });

  submitPaymentConversationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.SubmitPaymentConversationRequest),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.createPaymentFromClient(
        action.request,
        action.client,
        action.contact,
        action.channel,
        practice
      )
        .pipe(
          map((result: Payment) => {
            return PaymentsActions.SubmitPaymentRequestSuccess({payment: result});
          })
        );
    }),
    catchError(() => {
      return of(PaymentsActions.SubmitPaymentRequestFailed());
    })
  ));

  updatePaymentFilters$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.UpdatePaymentFilters),
    withLatestFrom(this.store.select(getCurrentPractice)),
    tap(([action, practice]) => {
      let url = `payments?`;
      const params = buildUrlParamsFromPaymentFilters(action.filters);
      url += params;
      this.router.navigateByUrl(url);
    }),
    map(([action, practice]) => {
      return PaymentsActions.GetPayments({filters: action.filters});
    })
  ));

  getPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.GetPayments),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.getPayments(action.filters, practice)
        .pipe(
          map((result: { payments: Payment[], total: number }) => {
            return PaymentsActions.GetPaymentsSuccess({...result});
          })
        );
    })
  ));

  resendPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.ResendPaymentLink),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.resendPayment(action.payment, practice, action.channel)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment resent successfully',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          })
        );
    })
  ));

  refundPayment$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.RefundPayment),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.refundPayment(action.payment, practice)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment refunded',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error processing refund',
              life: 5000
            });
            return of();
          })
        );
    })
  ));

  cancelPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CancelPaymentLinks),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.cancelPayments(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment(s) cancelled successfully',
              life: 5000
            });
          }),
          map(() => {
            return Noop();
          })
        );
    })
  ));

  retrySavePayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.RetrySavePaymentToPms),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.retrySavePayment(action.payment, practice)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment saved to PMS successfully',
              life: 5000
            });
          }),
          map((result: Payment) => {
            return PaymentsActions.RetrySavePaymentToPmsSuccess({payment: result});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Saving to PMS failed',
              life: 5000
            });
            return of(PaymentsActions.RetrySavePaymentToPmsFailed({payment: action.payment}));
          })
        );
    }),
  ));

  markPaymentsAsComplete$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.MarkPaymentsAsComplete),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.markAsComplete(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payments updated successfully',
              life: 5000
            });
          }),
          map(() => {
            return PaymentsActions.MarkPaymentsAsCompleteSuccess({payments: action.payments});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to update payments',
              life: 5000
            });
            return of(PaymentsActions.MarkPaymentsAsCompleteFailed({payments: action.payments}));
          })
        );
    }),
  ));

  capturePayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CapturePayments),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.capturePayments(action.payments, practice)
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Payment completed successfully',
              life: 5000
            });
          }),
          map(() => {
            return PaymentsActions.PaymentsCaptureSuccess({payments: action.payments});
          }),
          catchError(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Payment failed',
              life: 5000
            });
            return of(PaymentsActions.PaymentsCaptureFailed({payments: action.payments}));
          })
        );
    }),
  ));

  cashUpPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CashUp),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.paymentsService.cashUp(action.filters, practice)
        .pipe(
          map((payments: Payment[]) => {
            return PaymentsActions.CashUpSuccess({payments, includeCompleted: action.filters.statuses.includes(PaymentStatus.COMPLETE)});
          }),
          catchError(() => {
            return of(PaymentsActions.CashUpFailed());
          })
        );
    }),
  ));

  cashUpSuccessPayments$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentsActions.CashUpSuccess),
    mergeMap((action) => {
      exportPaymentAsCsv(action.payments, false);
      return of(Noop());
    }),
  ));
}
