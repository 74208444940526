import {Template} from '../models/Template';
import {Injectable} from '@angular/core';
import {TemplateStatus} from '../enums/template-status';
import {TemplateConsentRequiredOption} from '../enums/template-consent-required-option';
import {TemplateArchiveStatus} from '../enums/template-archive-status';
import {DefaultWhatsappTemplateDto} from '../interfaces/dto/default-whatsapp-template.dto';
import {TemplateSubmissionStatus} from "../enums/template-submission-status";
import {MergeFieldContent} from "../enums/merge-field-content";
import { WhatsappTemplateCategory } from '../enums/whatsapp-template-category';

@Injectable({
  providedIn: 'root'
})
export class DefaultTemplateAdapter {
    run(dto: DefaultWhatsappTemplateDto): Template {
      return {
        active: TemplateStatus.INACTIVE,
        body: dto.body,
        externalId: '0',
        id: '0',
        name: dto.name,
        practiceId: '0',
        status: TemplateSubmissionStatus.Approved,
        rejectedReason: null,
        category: WhatsappTemplateCategory.MARKETING,
        type: dto.type,
        attachMedia: false,
        addQuickReplies: false,
        quickReply: '',
        quickReply2: '',
        mergeFields: dto.placeholders.map((placeholder, index) => {
          return {
            id: index,
            placeholder: placeholder.placeholder,
            content: placeholder.content,
            templateId: 0,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
        }),
        consentRequired: TemplateConsentRequiredOption.NOT_REQUIRED,
        archived: TemplateArchiveStatus.NOT_ARCHIVED,
        edits: [],
      };
    }
}
