import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Patient } from 'src/app/models/Patient';
import { Observable, Subscription } from 'rxjs';
import { Practice } from '../../../models/Practice';
import { select, Store } from '@ngrx/store';
import { getCurrentPractice } from '../../../practices/state/selectors';
import { takeWhile } from 'rxjs/operators';
import { AppState } from '../../../state/reducers';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { PracticeFeature } from 'src/app/enums/practice-feature';

@Component({
  selector: 'patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  @Input() disabled?: boolean;
  @Input() patient?: Patient;
  @Input() changeDisabled = false;
  @Output() patientChanged = new EventEmitter();
  alive = true;
  insuranceEnabled = false;
  careplansEnabled = false;
  weightEnabled = false;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.practiceSub$?.unsubscribe();
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.updateInsuranceEnabled();
      this.updateWeightEnabled();
      this.updateCareplansEnabled();
    });
  }

  changePatient(): void {
    this.patientChanged.emit();
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  getCareplans(): string[] {
    if (this.patient && Array.isArray(this.patient.careplan)) {
      return this.patient.careplan;
    }

    return [];
  }

  updateInsuranceEnabled(): void {
    this.insuranceEnabled = false;

    if (practiceHasFeature(this.practice, PracticeFeature.INSURANCE)) {
      this.insuranceEnabled = true;
    }
  }

  updateWeightEnabled(): void {
    this.weightEnabled = false;

    if (practiceHasFeature(this.practice, PracticeFeature.WEIGHT)) {
      this.weightEnabled = true;
    }
  }

  updateCareplansEnabled(): void {
    this.careplansEnabled = false;

    if (practiceHasFeature(this.practice, PracticeFeature.CAREPLANS)) {
      this.careplansEnabled = true;
    }
  }
}
