<ng-container *ngIf="message">
  <ng-container *ngIf="showDate">
    <div class="message-date">{{ message.createdAt | dfnsFormat:'EEEE, do MMM' }}</div>
  </ng-container>
  <div class="message" [class.queued]="message.status == 'queued'" [class.outbound]="message.outbound">
    <ng-container *ngIf="message.author && !message.error">
      <div class="author">{{ message.author.firstName }} {{ message.author.lastName }}</div>
    </ng-container>
    <div class="message-content-wrap" [class.full-width]="fullWidth">
      <div class="message-content" [class.error]="!!message.error">
        <ng-container *ngIf="message.status == 'queued'">
          <div class="message-content-header">
            <div>Queued - awaiting client consent</div>
            <ng-container *ngIf="touchScreen">
              <div class="delete-queued-message" (click)="deleteMessage(message.id)">
                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.30811 4.61548C3.30811 4.06319 2.86039 3.61548 2.30811 3.61548C1.75582 3.61548 1.30811 4.06319 1.30811 4.61548H3.30811ZM17.1541 4.61548C17.1541 4.06319 16.7063 3.61548 16.1541 3.61548C15.6018 3.61548 15.1541 4.06319 15.1541 4.61548H17.1541ZM15.1541 4.61548V16.9999H17.1541V4.61548H15.1541ZM13.1541 18.9999H5.30811V20.9999H13.1541V18.9999ZM3.30811 16.9999V4.61548H1.30811V16.9999H3.30811ZM5.30811 18.9999C4.20354 18.9999 3.30811 18.1044 3.30811 16.9999H1.30811C1.30811 19.209 3.09897 20.9999 5.30811 20.9999V18.9999ZM15.1541 16.9999C15.1541 18.1044 14.2586 18.9999 13.1541 18.9999V20.9999C15.3632 20.9999 17.1541 19.209 17.1541 16.9999H15.1541Z" fill="#4F4F4F"/>
                  <rect y="3.07715" width="18.4613" height="2" rx="1" fill="#4F4F4F"/>
                  <rect x="7.69141" y="6.15381" width="11.5383" height="2" rx="1" transform="rotate(90 7.69141 6.15381)" fill="#4F4F4F"/>
                  <rect x="12.3076" y="6.15381" width="11.5383" height="2" rx="1" transform="rotate(90 12.3076 6.15381)" fill="#4F4F4F"/>
                  <rect x="5" width="8" height="2" rx="1" fill="#4F4F4F"/>
                  <rect x="7" width="4" height="2" rx="1" transform="rotate(90 7 0)" fill="#4F4F4F"/>
                  <rect x="13" width="4" height="2" rx="1" transform="rotate(90 13 0)" fill="#4F4F4F"/>
                </svg>
              </div>
            </ng-container>
            <ng-container *ngIf="!touchScreen">
              <div class="delete-queued-message with-hover" (click)="deleteMessage(message.id)" tooltipPosition="top" pTooltip="Delete from queue">
                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.30811 4.61548C3.30811 4.06319 2.86039 3.61548 2.30811 3.61548C1.75582 3.61548 1.30811 4.06319 1.30811 4.61548H3.30811ZM17.1541 4.61548C17.1541 4.06319 16.7063 3.61548 16.1541 3.61548C15.6018 3.61548 15.1541 4.06319 15.1541 4.61548H17.1541ZM15.1541 4.61548V16.9999H17.1541V4.61548H15.1541ZM13.1541 18.9999H5.30811V20.9999H13.1541V18.9999ZM3.30811 16.9999V4.61548H1.30811V16.9999H3.30811ZM5.30811 18.9999C4.20354 18.9999 3.30811 18.1044 3.30811 16.9999H1.30811C1.30811 19.209 3.09897 20.9999 5.30811 20.9999V18.9999ZM15.1541 16.9999C15.1541 18.1044 14.2586 18.9999 13.1541 18.9999V20.9999C15.3632 20.9999 17.1541 19.209 17.1541 16.9999H15.1541Z" fill="#4F4F4F"/>
                  <rect y="3.07715" width="18.4613" height="2" rx="1" fill="#4F4F4F"/>
                  <rect x="7.69141" y="6.15381" width="11.5383" height="2" rx="1" transform="rotate(90 7.69141 6.15381)" fill="#4F4F4F"/>
                  <rect x="12.3076" y="6.15381" width="11.5383" height="2" rx="1" transform="rotate(90 12.3076 6.15381)" fill="#4F4F4F"/>
                  <rect x="5" width="8" height="2" rx="1" fill="#4F4F4F"/>
                  <rect x="7" width="4" height="2" rx="1" transform="rotate(90 7 0)" fill="#4F4F4F"/>
                  <rect x="13" width="4" height="2" rx="1" transform="rotate(90 13 0)" fill="#4F4F4F"/>
                </svg>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngFor="let attachment of message.attachments; trackBy: trackAttachment">
          <message-attachment [attachment]="attachment" [outbound]="message.outbound" (loadedAttachment)="loadedAttached()"></message-attachment>
        </ng-container>
        <div>
          <span [innerHtml]="message.content | buildLinks | nl2br"></span>
          <ng-container *ngIf="showErrorInfoLink">
            <a [href]="helpLink" class="error-info" target="_blank"><i class="pi pi-question-circle"></i></a>
          </ng-container>
        </div>
      </div>


      <ng-container *ngIf="message.buttons && message.buttons.length > 0">
        <div class="message-buttons">
          <ng-container *ngFor="let button of message.buttons">
            <ng-container *ngIf="button.link">
              <a [href]="button.link" target="_blank" class="message-button">
                <ng-container *ngIf="button.type === buttonType.Link"><i class="pi pi-external-link"></i></ng-container>{{ button.text }}
              </a>
            </ng-container>
            <ng-container *ngIf="!button.link">
              <div class="message-button">
                <ng-container *ngIf="button.type === buttonType.Link"><i class="pi pi-external-link"></i></ng-container>{{ button.text }}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!hideTimeStamp">
      <div class="timestamp">
        <ng-container *ngIf="message.status != 'queued'">
          {{ message.createdAt | dfnsFormat:'dd/MM/yy HH:mm' }}
        </ng-container>
        <ng-container *ngIf="message.outbound && ((channel !== 'Facebook' && channel !== 'Instagram') || message.status === 'failed' || message.status === 'queued')">
          <message-status [status]="message.status"></message-status>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
