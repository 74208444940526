import {UntypedFormGroup} from '@angular/forms';
import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Patient} from '../../../models/Patient';
import {Client} from '../../../models/Client';
import {Template} from 'src/app/models/Template';
import {MergeFieldContent} from '../../../enums/merge-field-content';
import {select, Store} from '@ngrx/store';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Practice} from '../../../models/Practice';
import {getClientBalance} from '../../../helpers/get-client-balance';
import {getCurrencies} from '../../../state/selectors';
import {Currency} from '../../../models/Currency';
import {CurrencyPipe} from '@angular/common';
import {TemplateMergeField} from '../../../models/TemplateMergeField';
import {TemplateType} from '../../../enums/template-type';
import {prettifyTemplateType} from '../../../helpers/prettify-template-type';
import {MediaType} from '../../../enums/media-type';
import {TemplateSubmissionStatus} from '../../../enums/template-submission-status';
import {Media} from '../../../models/Media';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {EnvironmentService} from '../../../services/environment.service';
import {ReplacePlaceholderComponent} from '../replace-placeholder/replace-placeholder.component';
import { TemplateMergeFieldDo } from '../../../interfaces/template-merge-field.do.interface';
import { ConversationService } from '../../services/conversation.service';
import { Theme } from '../../../enums/theme';
@Component({
  selector: 'dialog-template-selector',
  templateUrl: './dialog-template-selector.component.html',
  styleUrls: ['./dialog-template-selector.component.scss'],
  providers: [DialogService],
})
export class DialogTemplateSelectorComponent implements OnInit, OnDestroy {
  @ViewChildren('customTemplateTypes') customTemplateTypes:
    | QueryList<ElementRef>
    | undefined;
  alive = true;
  result:
    | {
        text: string;
        mergeFields: {
          placeholderId: string;
          content: string;
        }[];
        template: Template;
        media?: Media;
        previewType?: string;
        buttonLink?: string;
      }
    | undefined;
  @Output() dialogClosed = new EventEmitter();
  templates: Template[] = [];
  client?: Client;
  patient?: Patient;
  buttonClass = '';
  includeMediaFilter = false;
  error = false;
  practice$?: Observable<Practice | null>;
  practice?: Practice | null;
  currencies: Currency[] = [];
  practiceCurrency?: Currency;
  helpLink = '';
  consentLink = '';
  templateFilterValue = '';
  filteredTemplates: Template[] = [];
  manualPlaceholders: {id: number, label: string}[] = [];
  showTemplatePlaceholderDialog = false;
  selectedTemplate: Template | null = null;
  placeholdersForm = new UntypedFormGroup({});
  selectPlaceholderDialog?: DynamicDialogRef;
  selectMediaDialog?: DynamicDialogRef;
  selectedMedia?: Media;
  mediaError: string | null = null;
  previewSrc: string | null = null;
  mediaType = MediaType;
  previewType = 'image';
  selectedTemplateType: TemplateType | null = null;
  templateTypes: TemplateType[] = Object.values(TemplateType).filter((type) =>
    [TemplateType.Campaign, TemplateType.Standard].includes(type)
  );
  selectedMediaType: MediaType | null = null;
  mediaTypes: MediaType[] = Object.values(MediaType);
  mediaName = '';
  approved: TemplateSubmissionStatus = TemplateSubmissionStatus.Approved;
  tmpTemplates: Template[] = [];
  buttonLink?: string;
  theme: string = Theme.DigitalPractice;
  constructor(
    private store: Store,
    private currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    private environmentService: EnvironmentService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private conversationService: ConversationService
  ) {
    this.templates = this.config.data.templates;
    this.client = this.config.data.client;
    this.patient = this.config.data.patient;
    this.buttonClass = this.config.data.buttonClass;
    this.includeMediaFilter = this.config.data.includeMediaFilter;
  }

  ngOnInit(): void {
    this.setFilteredTemplates();
    this.subscribeToCurrentPractice();
    this.subscribeToCurrencies();
    this.getHelpLink();
    this.getConsentLink();
    this.init();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  init(): void {
    this.tmpTemplates = this.templates;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive));

    this.practice$.subscribe((practice) => {
      this.conversationService.practice = practice;
      this.practice = practice;
      if(practice){
        this.theme = practice.theme ? practice.theme : Theme.DigitalPractice;
      }
      this.setPracticeCurrency();
    });
  }

  subscribeToCurrencies(): void {
    this.store
      .pipe(select(getCurrencies))
      .pipe(takeWhile(() => this.alive))
      .subscribe((currencies) => {
        this.currencies = currencies;
        this.setPracticeCurrency();
      });
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get(
      'helpUrl'
    )}/learn/section/digital-practice`;
  }

  getConsentLink(): void {
    this.consentLink = `${this.environmentService.get(
      'helpUrl'
    )}/learn/section/digital-practice/category/faq/post/what-is-consent`;
  }

  setPracticeCurrency(): void {
    if (this.currencies.length && this.practice) {
      this.practiceCurrency = this.currencies.find(
        (currency) => currency.currencyCode === this.practice?.currency
      );
    }
  }

  getTemplateTypeClass(type: TemplateType): string {
    const typeClass = prettifyTemplateType(type)
      .toLowerCase()
      .split(' ')
      .join('-');
    return `template-type-${typeClass}`;
  }

  setFilteredTemplates(): void {
    if (
      this.templateFilterValue === '' &&
      this.selectedTemplateType === null &&
      this.selectedMediaType === null
    ) {
      this.filteredTemplates = this.templates;
      return;
    }

    this.filteredTemplates = this.tmpTemplates.filter((template) =>
      this.templateMatchesFilters(template)
    );
  }

  applyTemplateTypeFilter(): void {
    if (this.selectedTemplateType === null) {
      this.filteredTemplates = this.templates;
      return;
    }

    this.filteredTemplates = this.tmpTemplates.filter((template) =>
      this.templateMatchesFilters(template)
    );
  }

  applyMediaTypeFilter(): void {
    if (this.selectedMediaType === null) {
      this.filteredTemplates = this.templates;
      return;
    }

    this.filteredTemplates = this.tmpTemplates.filter((template) =>
      this.templateMatchesFilters(template)
    );
  }

  private templateMatchesFilters(template: Template): boolean {
    const templateName = template.name?.toLowerCase() || '';
    const templateBody = template.body?.toLowerCase() || '';

    const filterName = this.templateFilterValue.toLowerCase();
    const filterType = this.selectedTemplateType;
    const filterMediaType = this.selectedMediaType;

    const nameMatches =
      templateName.includes(filterName) || templateBody.includes(filterName);
    const typeMatches =
      filterType === null ||
      template.type === filterType ||
      filterType.includes(template.type);
    const mediaTypeMatches =
      filterMediaType === null ||
      template.mediaType === filterMediaType ||
      template.mediaType === undefined ||
      filterMediaType.includes(template.mediaType || undefined);

    return nameMatches && typeMatches && mediaTypeMatches;
  }

  clearFilter(): void {
    this.templateFilterValue = '';
    this.resetTemplates();
  }

  resetTemplates(): void {
    this.templateFilterValue = '';
    this.selectedTemplateType = null;
    this.selectedMediaType = null;
    this.filteredTemplates = this.templates;
  }

  selectTemplate(template: Template): void {
    this.manualPlaceholders = this.getTemplateManualPlaceholders(template);
    this.selectedTemplate = template;

    if (
      this.manualPlaceholders.length > 0 ||
      this.selectedTemplate.attachMedia ||
      (this.selectedTemplate.button && this.selectedTemplate.type === TemplateType.Standard)
    ) {
      this.removeMedia();
      this.selectPlaceholder();
    } else {
      this.emitSelectTemplate(template);
    }
  }

  getTemplateManualPlaceholders(
    template: Template
  ): {id: number, label: string}[] {
    const response: {id: number, label: string}[] = [];

    template.mergeFields.forEach((mergeField) => {
      switch (mergeField.content) {
        case MergeFieldContent.PracticeName:
          if (!this.practice) {
            response.push({id: mergeField.id, label: mergeField.content});
          }
          break;
        case MergeFieldContent.ClientName:
          response.push({id: mergeField.id, label: mergeField.content});
          break;
        case MergeFieldContent.ClientPostcode:
          if (!this.client?.postcode) {
            response.push({id: mergeField.id, label: mergeField.content});
          }
          break;
        case MergeFieldContent.ClientBalance:
          if (!this.client?.accountStatus) {
            response.push({id: mergeField.id, label: mergeField.content});
          }
          break;
        case MergeFieldContent.PatientName:
          response.push({id: mergeField.id, label: mergeField.content});
          break;
        case MergeFieldContent.Custom:
          response.push({id: mergeField.id, label: mergeField.customName ?? 'Custom'});
          break;
      }
    });

    return response;
  }

  emitSelectTemplate(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[] = []
  ): void {
    const text = this.getTemplateText(template, placeholders);
    const mergeFields = this.getTemplateMergeFields(template, placeholders);
    const media = this.selectedMedia;
    const previewType = this.previewType;
    const buttonLink = this.buttonLink;
    this.emitTemplate(text, mergeFields, template, media, previewType, buttonLink);
    this.reset();
    this.removeMedia();
  }

  emitTemplate(
    text: string,
    mergeFields: TemplateMergeFieldDo[],
    template: Template,
    media?: Media,
    previewType?: string,
    buttonLink?: string
  ): void {
    this.result = {
      ...this.result,
      text,
      template,
      mergeFields,
      media,
      previewType,
      buttonLink
    };

    this.ref.close(this.result);
  }

  reset(): void {
    this.error = false;
    this.templateFilterValue = '';
    this.setFilteredTemplates();
    this.selectedTemplate = null;
    this.manualPlaceholders = [];
    this.showTemplatePlaceholderDialog = false;
  }

  getTemplateText(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    let templateText = template.body;

    template.mergeFields.forEach((mergeField) => {
      templateText = templateText.replace(
        mergeField.placeholder,
        this.getMergeFieldReplacement(mergeField, placeholders)
      );
    });

    return templateText;
  }

  getTemplateMergeFields(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ): TemplateMergeFieldDo[] {
    const mergeFields: TemplateMergeFieldDo[] = [];

    template.mergeFields.forEach((mergeField) => {
      mergeFields.push({
        placeholderId: mergeField.id.toString(),
        content: this.getMergeFieldReplacement(mergeField, placeholders),
      });
    });

    return mergeFields;
  }

  private getMergeFieldReplacement(
    mergeField: TemplateMergeField,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        return (
          this.practice?.name ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );

      case MergeFieldContent.ClientPostcode:
        return (
          this.client?.postcode ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientBalance:
        let replace: string | null = null;
        if (this.client?.accountStatus && this.practiceCurrency) {
          replace = getClientBalance(
            this.currencyPipe,
            this.client.accountStatus,
            this.practiceCurrency.currencyCode,
            this.practiceCurrency.currencySymbol
          );
        }

        return (
          replace ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.PatientName:
        return (
          this.patient?.name ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.Custom:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );
    }

    return mergeField.placeholder;
  }

  selectPlaceholder(): void {
    if (this.selectedTemplate) {
      this.conversationService.initialMessage.setValue(this.selectedTemplate.body)
      this.selectPlaceholderDialog = this.dialogService.open(
        ReplacePlaceholderComponent,
        {
          header: 'Replace template placeholders',
          modal: true,
          width: '800px',
          baseZIndex: 10000,
          data: {
            template: this.selectedTemplate,
            manualPlaceholders: this.manualPlaceholders,
            client: this.client,
            patient: this.patient,
            buttonClass: 'p-button-success',
          },
        }
      );

      this.selectPlaceholderDialog.onClose.subscribe((res) => {
        if (res) {
          if (res.media) {
            this.selectedMedia = res.media;
          }

          if (res.previewType) {
            this.previewType = res.previewType;
          }

          if (res.buttonLink) {
            this.buttonLink = res.buttonLink;
          }

          if (res.template) {
            this.emitSelectTemplate(res.template, res.placeholders);
          }
        }
      });
    }
  }

  removeMedia(): void {
    this.previewSrc = null;
    this.selectedMedia = undefined;
  }
}
