<div class="btn-row">
  <div class="col" [class.hidden]="!notesEnabled">
    <client-notes [client]="client"></client-notes>
  </div>
  <div class="col" [class.hidden]="!paymentsEnabled">
    <payment-history [client]="client" (opened)="paymentHistoryOpened()"></payment-history>
  </div>
  <div class="col" [class.hidden]="!productRequestsEnabled">
    <product-request-history [client]="client"></product-request-history>
  </div>
</div>
