import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Media } from '../../models/Media';
import { Template } from '../../models/Template';
import { UntypedFormControl } from '@angular/forms';
import { Practice } from '../../models/Practice';
import { TemplateMergeField } from '../../models/TemplateMergeField';
import { MergeFieldContent } from '../../enums/merge-field-content';
import { Client } from '../../models/Client';
import { Patient } from '../../models/Patient';

@Injectable({
  providedIn: 'root'
})

export class ConversationService {

  constructor(

  ) { }

  private getMergeFieldReplacement(
    mergeField: TemplateMergeField,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        return (
          this.practice?.name ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
        case MergeFieldContent.PatientName:
          return (
            this.patient?.name || placeholders.find(
              (p) => p.placeholderId === mergeField.id.toString()
            )?.replacement || mergeField.placeholder
          );
      case MergeFieldContent.ClientPostcode:
        return (
          this.client?.postcode ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.Custom:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );
    }

    return mergeField.placeholder;
  }

  getTemplateText(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    let templateText = template.body;

    template.mergeFields.forEach((mergeField) => {
      templateText = templateText.replace(
        mergeField.placeholder,
        this.getMergeFieldReplacement(mergeField, placeholders)
      );
    });

    return templateText;
  }

  updatePreviewConversation(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ){
    const text = this.getTemplateText(template, placeholders);
    this.initialMessage.setValue(text);
  }

  public initialMessage = new UntypedFormControl('');
  public practice?:Practice|null;
  public patient?:Patient|null;
  client?: Client;
}
