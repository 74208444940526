export const environment = {
  appVersion: require('../../package.json').version,
  name: 'Staging',
  production: false,
  secureCookie: true,
  appUrl: 'https://messaging-staging.vethelpdirect.com',
  backendUrl: 'https://api.messaging-staging.vethelpdirect.com/api',
  authUrl: 'https://auth-staging.vethelpdirect.com',
  helpUrl: 'https://help-staging.vethelpdirect.com',
  billingUrl: 'https://billing-staging.vethelpdirect.com',
  authClientId: '936d85f6-a25f-4d51-b73d-d8950faf4990',
  formsApiUrl: 'https://staging.forms.api.digitalpractice.vet',
  websocketUrl: 'https://api.messaging-staging.vethelpdirect.com',
  maxImageSize: 800,
  appLogo: '/assets/images/dp-thumb.svg',
  appWideLogo: '/assets/images/dp-logo.svg',
  login: {
    services: ['vhd', 'rx-works'],
    logo: '/assets/images/dp-logo.svg',
    logoSize: 'large',
  },
  gtag_code: 'G-PNL5E45G8R',
  oneSignal: {
    appId: 'a012b4f5-e0b0-45f7-a8f1-d92b03016f34',
    safariWebId: ''
  },
  jwtPrefix: 'STAGING_MESSAGING_',
  noPracticesMessage: 'You do not have access to any practices. Please contact your system administrator.',
  campaignLimit: 750,
  conversationsPerPage: 20,
  productRequestsPerPage: 20,
  clientsPerPage: 20,
  paymentsPerPage: 20,
  templateMessageLength: 1000,
};
