import { MergeFieldContent } from '../enums/merge-field-content';

export function prettifyTemplateContent(type: MergeFieldContent | string): string {
  switch (type) {
    case MergeFieldContent.PracticeName:
      return 'Practice name';
    case MergeFieldContent.ClientName:
      return 'Client name';
    case MergeFieldContent.ClientPostcode:
      return 'Client postcode';
    case MergeFieldContent.ClientBalance:
      return 'Client balance';
    case MergeFieldContent.PatientName:
      return 'Patient name';
    case MergeFieldContent.PaymentAmount:
      return 'Payment amount';
    case MergeFieldContent.PaymentType:
      return 'Payment type';
    case MergeFieldContent.PaymentExpiry:
      return 'Payment expiry date';
    case MergeFieldContent.PaymentId:
      return 'Payment ID';
    case MergeFieldContent.PaymentLink:
      return 'Payment link';
    case MergeFieldContent.PaymentSentAt:
      return 'Payment sent date';
    case MergeFieldContent.PaymentMessage:
      return 'Payment message';
    case MergeFieldContent.NotificationText:
      return 'Notification text';
    case MergeFieldContent.NotificationLink:
      return 'Notification link';
    case MergeFieldContent.FormName:
      return 'Form name';
    case MergeFieldContent.AppUrl:
      return 'Digital Practice url';
    case MergeFieldContent.Custom:
      return 'Custom';
    default:
      return type;
  }
}
